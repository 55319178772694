<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "yuè",
              hanzi: "月",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "hào",
              hanzi: "号",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "jīntiān",
              hanzi: "今天",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "shēngrì",
              hanzi: "生日",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              // {
              //   pinyin: "nǐ",
              //   hanzi: "你",
              //   symbol: "",
              //   id: 1,
              //   symbolType: 0,
              // },

              // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

              {
                pinyin: "zhīshídiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
             
              {
                pinyin: "月",
                hanzi: "“月”",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "hé",
                hanzi: "和",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
               {
                pinyin: "hào",
                hanzi: "“号”",
                symbol: "",
                group: 1,
                line: 1,
                index: 54,
                symbolType: 0,
              },
              
              {
                pinyin: "biǎo dá",
                hanzi: "表达",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "jù tǐ",
                hanzi: "具体",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
               {
                pinyin: "de",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
               {
                pinyin: "rì qī",
                hanzi: "日期",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "jīntiān",
                hanzi: "今天",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "\xa0",
                hanzi: "12",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "yuè",
                hanzi: "月",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "\xa0",
                hanzi: "1",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
               {
                pinyin: "hào",
                hanzi: "号",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 2,
                index: 6,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "xún wèn",
              hanzi: "询问",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "rì qī",
              hanzi: "日期",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "bìng",
              hanzi: "并",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "néng gòu",
              hanzi: "能够",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "huí dá ",
              hanzi: "回答",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
           }
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>