<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      :isBlueBackground="true"
      :lesson="28"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "jǐ yuè jǐ hào",
        hanzi: "几月几号",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/image-1.svg`),
          pinyin: "qī yuè shíjiǔ hào",
          hanzi: "7月19号",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/image-2.svg`),
          pinyin: "sān yuè èrshísì hào",
          hanzi: "3月24号",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/image-3.svg`),
          pinyin: "jiǔ yuè sānshí hào",
          hanzi: "9月30号",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/image-4.svg`),
          pinyin: "èr yuè shíbā hào",
          hanzi: "2月18号",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/image-5.svg`),
          pinyin: "bā yuè shíliù hào",
          hanzi: "8月16号",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-2/menu-5.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>