<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "yuè",
          hanzi: "月",
          english: "month",
          image: require(`@/assets/img/12-Vocabulary/lesson-57-yue-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "hào",
          hanzi: "号",
          english: "date",
          image: require(`@/assets/img/12-Vocabulary/lesson-57-hao-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "jīntiān",
          hanzi: "今天",
          english: "today",
          image: require(`@/assets/img/12-Vocabulary/lesson-57-jintian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "shēngrì",
          hanzi: "生日",
          english: "birthday",
          image: require(`@/assets/img/12-Vocabulary/lesson-57-shengri-bubble.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
