<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="57"
      :titleInfo="titleInfo"
      :hasSentence="false"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/background.svg"),
      titleInfo:{
        pinyin:"Zhè shì jǐ yuè?",
        hanzi:"这是几月？"
      },
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-2-done.svg"),
          id: 1,
          index: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-3-done.svg"),
          id: 2,
          index: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-5-done.svg"),
          id: 3,
          index: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-6-done.svg"),
          id: 4,
          index: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-7-done.svg"),
          id: 5,
          index: 5,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-10-done.svg"),
          id: 6,
          index: 6,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-11-done.svg"),
          id: 7,
          index: 7,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-12-done.svg"),
          id: 8,
          index: 8,
        },
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-2.svg"),
        },
        {
          id: 2,
          index: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-3.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-5.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-6.svg"),
        },
        {
          id: 5,
          index: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-7.svg"),
        },
        {
          id: 6,
          index: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-10.svg"),
        },
        {
          id: 7,
          index: 7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-11.svg"),
        },
        {
          id: 8,
          index: 8,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-57/calendar-12.svg"),
        },
      ],
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











