<template>
  <div class="game-container">
    <DragSameCorrectPicGame
      :answerList="answerList"
      :optionsList="optionsList"
      @changeAnswerListStatus="changeAnswerListStatus"
      @changeOptionsListStatus="changeOptionsListStatus"
      :starNum="5"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DragSameCorrectPicGame from "@/components/Course/GamePage/DragSameCorrectPicGame";
export default {
  data() {
    return {
      curIndex: null,
      originList: [],
      titleInfo:{
        pinyin:"Zhè shì shéi de shēngrì dàngāo?",
        hanzi:"这是谁的生日蛋糕？",
      },
      bgImage: require("@/assets/img/03-Backgrounds/level-1-chapter-6-background-blue.svg"),
      answerList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/1-person.svg"),
        },
        {
          index: 2,
          id: 2,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/2-person.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/3-person.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/4-person.svg"),
        },
        {
          id: 5,
          index: 5,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/5-person.svg"),
        },
      ],

      optionsList: [
        {
          id: 1,
          index: 1,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/1-button.svg"),
        },
        {
          id: 2,
          index: 2,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/2-button.svg"),
        },
        {
          id: 3,
          index: 3,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/3-button.svg"),
        },
        {
          id: 4,
          index: 4,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/4-button.svg"),
        },
        {
          id: 5,
          index: 5,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-57/5-button.svg"),
        },
      ],
    };
  },
  components: {
    DragSameCorrectPicGame,
  },
  created() {
    this.originList = JSON.parse(JSON.stringify(this.answerList));
  },
  methods: {
    changeAnswerListStatus(index) {
      this.answerList.forEach((item, curIndex) => {
        if (item.index === index) {
          this.curIndex = curIndex;
        }
      });
      console.log(index, this.curIndex);
      this.answerList.splice(this.curIndex, 1);
    },
    changeOptionsListStatus(id, index) {
      console.log(id);
      this.optionsList.forEach((ele) => {
        if (ele.index === id) {
          ele.done = true;
          ele.bgImg = this.originList[index - 1].image;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











