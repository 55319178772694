<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      :isBlueBackground="true"
      :lesson="28"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Jīntiān jǐ yuè jǐ hào？",
        hanzi: "今天几月几号？",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/image-1.svg`),
          pinyin: "Jīntiān liù yuè érshísān hào.",
          hanzi: "今天6月23号。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/image-2.svg`),
          pinyin: "Jīntiān liù yuè shíjiǔ hào.",
          hanzi: "今天6月19号。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/image-3.svg`),
          pinyin: "Jīntiān liù yuè sānshí hào.",
          hanzi: "今天6月30号。",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-3/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>